export const secrets = {
    googleKey: "AIzaSyDNT2TWPaDIqcUIPNZN5ulbCzaJF3E_naI",
    googleUrl: "https://www.googleapis.com/language/translate/v2",
    youdaoUrl: "/youdao",
    baiduAppId: "20170227000039947",
    baiduSecretKey: "1shXij8y_I0GKkq7gW12",
    baiduUrl: "/baidu",
    bingKey1: "d3a127e234e34b80a602eb3d558ac6ae",
    bingKey2: "4de616336aa849f5bb7541fa184641b5",
    bingTranslateUrl: "https://api.cognitive.microsofttranslator.com/translate",
    bingDictionaryLookupUrl: "https://api.cognitive.microsofttranslator.com/dictionary/lookup",
    bingDictionaryExampleUrl: "https://api.cognitive.microsofttranslator.com/dictionary/examples",
    oxfordEntryUrl: "/oxford/api/v2/entries/en-gb/",
    oxfordSentenceUrl: "/oxford/api/v2/sentences/en/",
    recordUrl: "/record",
    datamuseUrl: "https://api.datamuse.com/sug",
    websterKey: "06bf9fae-da8c-498e-8822-9a1c75db89f7",
    websterUrl: "https://www.dictionaryapi.com/api/v3/references/collegiate/json/",
    websterSoundBaseUrl: "https://media.merriam-webster.com/soundc11/",
    urbanDictionaryUrl: "/urban_dictionary",
    amazonUrl: "/amazon",
    deeplUrl: "/deepl",
    caiyunUrl: "/caiyun",
    chatgptUrl: "/chatgpt",
    llamaUrl: "/llama",
    llama2Url: "/llama2",
    longmanKey: "ac2e449fca45531fa8ec661b073a88f6",
    longmanUrl: "https://api.pearson.com/v2/dictionaries/ldoce5/entries",
    longmanSoundBaseUrl: "https://api.pearson.com",
}
